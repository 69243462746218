// Override .ProseMirror here until MUI is updated to v5 and we pick a 
// styling engine to use Remirror's `ThemeProvider`
.remirror-theme {
  .ProseMirror {
    box-shadow: none !important;
    padding: 0 !important;
    min-height: 0px !important;

    p {
      font-size: 14px;
    }
  }
}

.remirror-editor.ProseMirror {
  // makes gray bar at right disappear
  // not sure if this behavior is actually important though
  overflow-y: inherit !important;
  font-family: "Open Sans";
}

.floating-cursor {
  position: absolute;
  pointer-events: none;
  background-color: pink;
}

.transcription-paragraph {
  padding-bottom: 32px;
  position: relative;
  .transcription-paragraph-time {
    @include flex(row, space-between, center);
    margin-bottom: 8px;
    .time {
      font-size: 12px;
      font-style: italic;
      color: gray;
    }
    .speaker {
      font-size: 10px;
      padding: 2px 8px;
      line-height: 20px;
      border-radius: 6px;
      background: white;
      color: #1d1c29;
      border: 1px solid #818c8e;
    }
  }
}
