.text-sm {
  font-size: 12px;
}
.float-right {
  float: right;
}
.width-100p {
  width: 100%;
}
.width-50p {
  width: 50%;
}
.height-100p {
  height: 100%;
}
.p-10 {
  padding: 10px;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.text-align-right {
  text-align: right;
}
.link-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.pos-relative {
  position: relative;
}
.dis-inline-block {
  display: inline-block;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#container {
  display: inline-block;
  position: relative;
  width: 50%;
}

.top-right {
  position: absolute;
  top: 0px;
  right: 0px;
}

.gray-text {
  color: gray;
}

.square-max-md {
  max-width: 250px;
  max-height: 250px;
}
.square-max-sm {
  max-width: 150px;
  max-height: 150px;
}
.background-white {
  background: white;
}