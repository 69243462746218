@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap");

html {
  font-size: 14px;
}
a {
  color: #595c62;
}
a:hover {
  color: #595c62;
  cursor: pointer;
  text-decoration: underline;
}

.study-title {
  font-size: 35px;
  font-family: "Open Sans";
  color: white !important;
}

.white-enforce {
  color: white !important;
}

body {
  font-family: "Open Sans";
}

.dropdown-toggle {
  color: white !important;
}

.card-title {
  font-size: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.content {
  min-height: calc(100vh - 55px) !important;
}
.files-content {
  min-height: calc(100vh - 55px) !important;
  background: #fdfdfd;
}
.padding-div {
  padding: 15px;
}
.padding-div-2 {
  padding: 15px 30px 15px 30px;
  max-width: 1400px;
}

.bg-success {
  background-color: #7ec6b2 !important;
}

.bg-warning {
  background-color: #c3726d !important;
}

.play-icon:hover {
  color: green;
  cursor: pointer;
}

.search-box {
  background-color: None;
  border: 0;
  border: none;
  padding: 4px;
  color: black !important;
  margin-bottom: 0px;
  margin-right: 0px;
  font-size: 16px;
  background-color: #f7f7f8;
}

.center-row {
  text-align: center;
  align-content: center;
}

.search-icon {
  color: #595c62;
  font-size: 16px;
}

.search-box-icon {
  vertical-align: middle;
  display: inline-block;
}

.search-box-container {
  margin-right: 20px;
  width: 300px;
  border: solid 1px #d6d5d5;
  border-radius: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin-top: 15px;
  float: right;
}

.navbar-search {
  width: 130px;
  border: none;
  margin-left: 10px;
  color: black;
  font-size: 14px;
  transition: width 0.5s;
}

.small-search-container {
  display: inline;
  margin-right: 12px;
  background: white;
  border: 1px solid #c7c7c7;
  padding: 5px;
  border-radius: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.small-search-container input {
  width: 120px;
  border: none;
  margin-left: 10px;
  color: black;
  font-size: 12px;
  transition: width 0.5s;
}
.conversations-search {
  border-radius: 15px;
  width: calc(100% - 40px);
  margin: 12px 20px;
  margin-top: 0px;
  display: inline-block;
}

.sidebar .logo {
  min-height: 54px;
  background: #1d1c29;
  text-align: center;
  border: none !important;

  overflow: visible;
  z-index: 6;
  padding-top: 20px;
  padding-bottom: 30px;
}

.logo-text {
  font-size: 11px;
  font-weight: 600;
  color: white;
  font-family: Poppins, sans-serif;
}

.button-logo {
  margin-top: 5px;
  width: 44px;
  height: auto;
  overflow: visible;
  z-index: 7;
}
.button-logo:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
  filter: brightness(1.2);
  opacity: 0.8;
}

.navbar-container {
  display: flex;
  align-items: center;
}
.navbar-container-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
.navbar-container-left {
  flex-grow: 1;
  display: table;
  min-height: 54px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.navbar-container-center {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.navbar-account {
  cursor: pointer;
  border: none;
  background: none;
}

.thumbnail-image {
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

.avatar-text {
  color: black;
  margin: 0px 5px;
  margin-top: 15px;
}

.caret {
  color: #595c62;
}
.caret:hover {
  color: #595c62 !important;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  color: #595c62 !important;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #595c62;
  border-top-color: #595c62;
}
.dropdown.open {
  border-bottom-color: #7ec6b2 !important;
  border-top-color: #7ec6b2 !important;
}

.MuiChip-root.survey-pending {
  background-color: #daaa64;
  color: white;
  width: 80px;
  text-align: center;
}
.MuiChip-root.survey-done {
  background-color: #639e8f;
  color: white;
  width: 80px;
  text-align: center;
}
.survey-backlog {
  background-color: #c3726d;
  color: white;
  width: 80px;
  text-align: center;
  border-radius: 10px;
}
.MuiChip-root.survey-draft {
  background-color: gray;
  color: white;
  width: 80px;
  font-size: 12px;
}
.MuiChip-root.beta {
  background-color: #1691cb;
  color: white;
  width: 50px;
  font-size: 11px;
  margin-left: 12px;
  margin-top: -6px;
  height: 20px;
}

input[type="text"].login-field {
  background-color: white;
  border: 0;
  border: none;
  width: 100%;
  padding: 4px;
  color: #595c62 !important;
  margin-bottom: 0px;
  margin-right: 0px;
  font-size: 16px;
  border-radius: 20px;
}
.login-input-container {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  border: solid 1px lightgray;
  border-radius: 20px;
  margin-bottom: 20px;
}
.login-icon {
  color: #595c62;
  vertical-align: middle;
  margin-top: 3px;
  margin-bottom: auto;
  margin-left: 15px;
  font-size: 20px;
}
.login-button {
  border: 0;
  margin: 0 2px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #595c62;
  color: #fff;
  font-weight: 100;
  border: solid 1px #595c62;
  width: 300px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
}
.sign-in-sign-up {
  margin-bottom: 20px;
  margin-left: 15px;
  text-align: center;
  font-size: 24px;
  /* font-family: "Din Pro Light"; */
}
.login-footer {
  font-size: 16px;
  text-align: center;
  color: #595c62;
  margin-top: 40px;
}
.login-image {
  margin-top: 25px;
  width: 150px;
  height: auto;
}
.login-title-container {
  margin-top: 30px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 600px) {
  .MuiToggleButton-root.sign-in-usage-toggle-group {
    padding: 6px !important;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

.question-container {
  padding: 30px;
  flex-grow: 1;
  width: 100%;
}
.info-container {
  padding: 20px;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 20px;
}
.question-header {
  width: 100%;
  position: relative;
}
.question-container.preview {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0px;
  position: relative;
}
.support-content-wrapper {
  min-height: calc(100vh - 60px);
  width: 100%;
  position: relative;
}
.support-container {
  padding: 10px 30px;
  margin-bottom: 10px;
  flex-grow: 1;
}
.question-outer-container {
  display: flex;
  justify-content: center;
}

.dummy-question-container {
  height: 50px;
  width: 200px;
  margin: 10px;
  font-size: 16px !important;
}
.question-condensed {
  cursor: pointer;
}
.question-condensed-title.welcome {
  font-size: 30px;
}

.welcome-screen-condensed-container {
  display: flex;
  justify-content: center;
}

.question-condensed:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
}
.restricted-container {
  max-width: 1000px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}
.dummy-question-outer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.dummy-question-button {
  background-color: #507b82 !important;
  opacity: 0.7 !important;
}
.opacity-half {
  background: rgba(255, 255, 255, 0.5) !important;
}
h2.question-header {
  font-size: 18px;
  color: #595c62;
  font-weight: bold;
  font-family: "Open Sans";
  margin-top: 10px;
}
p.selection-header {
  font-size: 12px;
  color: #595c62;
  margin-bottom: 0px;
}
h3.demographic {
  font-size: 14px;
  margin-top: 10px;
}
h3.demographic a {
  color: #28bf8f;
}
h3.audience-header {
  font-size: 18px;
  color: #595c62;
  margin-top: 10px;
}
h2.audience-header {
  font-size: 18px;
  color: #595c62;
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
}
.support-header {
  font-size: 32px;
  color: #595c62;
  margin-top: 35px;
  margin-bottom: 25px;
  font-weight: 300;
}
.knowledgebase-button {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.support-text {
  color: #595c62;
  font-weight: 300;
  font-size: 18px;
  max-width: 1000px;
  margin: 0 auto;
}

.support-text-box {
  padding: 15px;
  color: #595c62;
  font-weight: 300;
  font-size: 18px;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 40px;
  border: 3px solid rgba(114, 108, 102, 0.08);
  box-sizing: border-box;
  width: 200px;
  border-radius: 10px;
  text-align: center;
}
.question-type {
  font-size: 16px;
  color: #595c62;
}
.demo-type {
  font-size: 18px;
  color: #595c62;
  font-weight: bold;
}
select.question-select {
  border: 0;
  border: solid 1px lightgray;
  border-radius: 3px;
  background-color: transparent;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  padding: 4px;
}
textarea.question-text-area {
  width: 100%;
  height: 100px;
  border-radius: 3px;
  border: solid 1px lightgray;
  resize: none;
  color: #595c62;
  margin-bottom: 5px;
}
textarea.location-text-area {
  width: 100%;
  height: 30px;
  border-radius: 3px;
  border: solid 1px lightgray;
  resize: none;
  color: #595c62;
}

.new-title {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin-top: 10px;
}

/* Overrides breadcrumb styling */
.breadcrumbs-title .MuiTypography-body1 {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 14px !important;
  color: #9d9ea0;

  margin: 0;
}
.breadcrumbs-title li:last-child {
  color: #595c62;
}
.breadcrumbs-title li > a:hover {
  color: #595c62;
  text-decoration: none;
}

.add-question-button {
  border: 0;
  margin: 0 2px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
  color: #595c62;
  font-weight: 100;
  border: solid 1px lightgray;
  width: 200px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
  float: right;
  vertical-align: middle;
  margin-top: 10px;
  margin-right: 10px;
}
.add-question-button:hover {
  background-color: lightgray;
}
.back-button {
  width: 200px;
  float: left;
}

.x-button {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  font-size: 16px;
  color: #595c62;
  display: flex;
  align-items: center;
}
.x-button.tight {
  right: 10px;
  top: 10px;
}
.x-button.clickable {
  cursor: pointer;
}
.x-button.clickable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.x-button.small {
  font-size: 14px;
}

.tray-top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}
.tray-top-left {
  position: absolute;
  top: 5px;
  left: 5px;
}
.tray-top-timeline {
  background: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 5px 10px;
  z-index: 200;
  cursor: pointer;
}
.tray-icon {
  color: rgb(44, 44, 44);
}
.tray-icon:hover {
  opacity: 0.5;
}

.x-button-bottom {
  position: absolute;
  cursor: pointer;
  right: 20px;
  bottom: 20px;
  font-size: 16px;
  color: #595c62;
  display: flex;
  align-items: center;
}
.x-button-bottom-center {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
}
.x-button-alternative {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 16px;
  color: #595c62;
  display: flex;
  align-items: center;
}
.icon-container {
  padding: 5px;
}
.icon-container:hover {
  color: lightgray;
  cursor: pointer;
}

input[type="text"].age-inputs {
  background-color: white;
  border: 0;
  /* border: solid 1px #d6d5d5; */
  width: 100%;
  padding: 4px;
  color: #595c62 !important;
  font-size: 12px;
  border-radius: 3px;
  vertical-align: middle;
  height: 30px;
}

.age-container {
  border: solid 1px #d6d5d5;
}
.age-subcontainer-left {
  height: 30px;
  /* text-align: right; */
  color: #595c62 !important;
  float: left;
  width: 35px;
  text-align: center;
}
.age-subcontainer-right {
  color: #595c62 !important;
  float: left;
  line-height: 30px;
  width: 30px;
  margin-right: 10px;
}
/* .row {
  margin-right: 0px;
  margin-left: 0px;
} */
.column {
  float: left;
  width: 50%;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.pseudo-col {
  float: left;
  margin-left: 15px;
  border: solid 1px #d6d5d5;
  border-radius: 3px;
}
.pseudo-row {
  margin-left: 15px;
  height: 30px;
}

.max-min-age {
  color: lightgray;
  font-size: 12px;
}

.country {
  background-color: #50a28f;
  color: white;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 7px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px;
}

.location-input {
  border: solid 1px #d6d5d5;
  margin-right: 10px;
}

.custom-demo-text-area {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: solid 1px lightgray;
  resize: none;
}

.mouse-pointer {
  cursor: pointer;
}

input[type="text"].survey-deployment {
  background-color: white;
  border: solid 1px lightgray;
  width: 100%;
  padding: 4px;
  color: #595c62 !important;
  margin-bottom: 10px;
  margin-right: 0px;
  border-radius: 3px;
  float: left;
}
input[type="text"].num-responses {
  background-color: white;
  border: solid 1px lightgray;
  width: 200px;
  padding: 4px;
  color: #595c62 !important;
  margin-bottom: 5px;
  margin-right: 0px;
  border-radius: 3px;
}
select.deployment-select {
  border: 0;
  border: solid 1px lightgray;
  border-radius: 3px;
  background-color: transparent;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  padding: 4px;
  color: #595c62;
}

.demo-button-container {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  border: solid 1px lightgray;
  margin-left: 20px;
  float: left;
  position: absolute;
  left: 0;
  bottom: 0;
  position: relative;
  text-align: center;
  padding-top: 3px;
  margin-top: 10px;
}
.demo-button-container:hover {
  cursor: pointer;
}
.demo-button-image {
  width: 100%;
  height: auto;
  overflow: none;
  float: bottom;

  position: absolute;
  left: 0;
  bottom: 5px;
}

.demo-title-container {
  padding-bottom: 5px;
}
.light-gray-background {
  background: #f7f7f8;
}

input[type="radio"] {
  margin-right: 10px;
  border: solid 4px #d6d5d5;
}
.gender-radio-outer-container.left {
  height: 150px;
  border-right: solid 1px lightgray;
}
.gender-radio-outer-container.right {
  height: 150px;
  color: #595c62;
}
.male-famale-container {
  float: left;
  width: 150px;
}
.radio-text {
  font-size: 20px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #7ec6b2 !important;
}
.MuiIconButton-root {
  padding: 4px !important;
}
.MuiRadio-colorPrimary {
  color: #7ec6b2;
}

.add-response {
  color: gray;
  font-size: 14px;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
}
.add-response.anchor {
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0.2;
}
.add-response.anchor.selected {
  opacity: 1;
}
.add-response.anchor.displaynone {
  display: none;
}
.add-response:hover {
  color: lightgray;
  cursor: pointer;
}

td:hover {
  cursor: pointer;
}

.audience-slider {
  width: 100%;
}
.audience-size {
  font-size: 16px;
  line-height: 30px;
  border: solid 1px #d6d5d5;
  width: 100px;
  margin-left: 10px;
  border-radius: 3px;
}
.playpause-button {
  font-size: 24px;
  color: #595c62;
  cursor: pointer;
  margin-top: 20px;
  line-height: 50px;
}
.playpause-button:hover {
  color: lightgray;
}
.playpause-button-smaller {
  font-size: 24px;
  color: #595c62;
  cursor: pointer;
  margin-top: 20px;
}
.playpause-button-smaller:hover {
  font-size: 24px;
  color: lightgray;
}
.card-stats .icon-big {
  color: #595c62;
}
fieldset legend {
  border-bottom: none !important;
  color: #595c62 !important;
}
.login-logo {
  width: 120px;
}

.premium-popup {
  font-family: "DM Sans", "Inter", Arial;
  padding: 20px;
  text-align: center;
}

.premium-popup h1,
.premium-popup h3 {
  font-family: "DM Sans", "Inter", Arial;
  font-weight: 700;
}
.premium-popup p {
  font-family: "DM Sans", "Inter", Arial;
}
/* .MuiButton-contained {
  background-color: #507b82 !important;
} */
.MuiButtonBase-root.upgrade-button {
  background-color: transparent !important;
  border: 1px solid hsla(216.279, 64.18%, 13.14%, 1);
  border-radius: 20px;
  color: hsla(216.279, 64.18%, 13.14%, 1) !important;
  height: 35px;
  margin: 8px;
  min-width: 92px;
}
.MuiButtonBase-root.upgrade-button:hover {
  background-color: hsla(216.279, 64.18%, 13.14%, 1) !important;
}
.upgrade-button .MuiButton-label {
  color: hsla(216.279, 64.18%, 13.14%, 1) !important;
}
.MuiButtonBase-root.upgrade-button:hover .MuiButton-label {
  color: white !important;
}
.green-text-accent {
  display: inline-block;
  padding-right: 0.25em;
  padding-left: 0.25em;
  border-radius: 8px;
  background-color: rgba(98, 203, 136, 0.18);
  color: #62cb88;
}
.plan-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.plan-inner-container {
  flex-direction: column;
}

.grid-check-plan {
  text-align: left;
}
.check-block-plan {
  width: 200px;
  margin: 12px 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.check-plan {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  flex: 0 0 auto;
  border-radius: 50%;
}
.check-plan > svg {
  display: block;
  margin: auto;
  height: 100%;
  width: 12px;
}
.check-plan.free {
  background-color: rgba(18, 179, 97, 0.1);
}
.check-plan.premium {
  background-color: rgba(87, 114, 255, 0.1);
}
.check-plan.enterprise {
  background-color: rgba(255, 101, 1, 0.1);
}
.text-check-plan {
  font-family: "DM Sans", "Inter", Arial;
  font-weight: 500;
  flex-grow: 0;
  line-height: 130%;
  padding: 0 12px;
  padding-top: 6px;
  text-align: left;
  align-self: flex-start;
  height: 100%;
  vertical-align: middle;
}

/* .grid-check-plan {
  text-align: left;
  width: 100%;
  margin-bottom: 34px;
  justify-items: start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 16px;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}
.check-block-plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 180px;
}
.check-plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  margin-right: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: rgba(255, 101, 1, 0.1);
} */

.MuiButton-label {
  color: white;
}
.MuiFormLabel-root.Mui-focused {
  color: #507b82 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #595c62 !important;
}
.MuiFormControlLabel-root {
  margin-left: -5px !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #595c62 !important;
}
.MuiTypography-colorPrimary {
  color: #595c62 !important;
}
.MuiOutlinedInput-root {
  color: #595c62 !important;
}

.start-survey-image {
  width: 750px;
  height: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* .center-container {
  text-align: center;
  display: block;
} */
.MuiFormControl-root.small-margin-bottom {
  margin-bottom: 5px;
}

.extra-margin-top {
  margin-top: 40px;
}
.extra-margin-bottom {
  margin-bottom: 40px;
}
.faq-response {
  padding-bottom: 20px;
  font-size: 14px;
  text-align: left;
}

h2.interview-title {
  font-size: 32px;
  color: #9da7bb;
}

.table-text {
  font-weight: bold !important;
}

.full-conversation-table {
  margin-bottom: 50px;
}

.MuiSwitch-switchBase {
  top: 4px !important;
  left: 4px !important;
}

head .page-title-button .MuiButton-contained {
  /* background-color: rgb(29, 117, 210) !important; */
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.page-title-button .MuiSwitch-root {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.upload-button .MuiButtonBase-root {
  background-color: #a4a4a4 !important;
}

/* File Upload Popup */

.form-group.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.form-group.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.form-group.files {
  position: relative;
}
.form-group.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.form-group.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.file-upload-popup {
  margin-top: 20px;
}

/* Starring Button */
.MuiIconButton-colorSecondary {
  color: grey !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #595c62 !important;
}

/* Profile (Settings) Page */

.profile-image {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
}
h3.profile-title {
  font-size: 26px;
  margin-top: 0px;
}
.profile-button-margin {
  margin-top: 16px;
  margin-bottom: 8px;
  cursor: pointer;
}
.profile-edit-buttons {
  display: flex;
  justify-content: flex-end;
}

.cancel-button .MuiButton-label {
  color: grey !important;
}
.cancel-plan-button {
  background: white !important;
  border: 1px solid rgb(163, 83, 83) !important;
}
.cancel-plan-button .MuiButton-label {
  color: rgb(163, 83, 83) !important;
}
.cancel-plan-button-group .MuiButton-label {
  color: grey !important;
}

.delete-button {
  background: rgb(204, 68, 68) !important;
  border: 1px solid rgb(128, 42, 42) !important;
}
.delete-plan-button .MuiButton-label {
  color: rgb(128, 42, 42) !important;
}
.delete-plan-button-group .MuiButton-label {
  color: grey !important;
}

.footer-custom {
  position: fixed; /* instead of absolute */
  bottom: 0;
  left: 260;
  width: calc(100% - 200px - 130px);
  margin-left: 50px;
  text-align: left;
  z-index: 10;
}

.waveform-container {
  padding: 20px;
}

.MuiPaper-root.audio-player {
  background-color: #f0f0f0;
  border: solid 1px gray;
}

/* Make sound waveform fill max width */
.sound-max-width {
  display: flex;
  justify-content: space-between;
}

.waveform-inner-container {
  float: left;
  width: 150px;
}
.waveform-inner-container.large {
  float: left;
  width: calc(100% - 120px);
}

.play-button-container {
  float: left;
  width: 35px;
}
.play-button-container.large {
  float: left;
  width: 50px;
}

.audio-player-outer {
  height: 50px;
  line-height: 50px;
  width: 250px;
}
.audio-player-time {
  font-weight: bold;
  height: 50px;
  width: 50px;
  line-height: 50px;
  float: left;
  color: #595c62;
  text-align: right;
}
.audio-player-time.large {
  height: 50px;
  width: 50px;
  line-height: 50px;
  float: left;
  font-size: 16px;
  margin-left: 10px;
}

.sidebar-shadow {
  -webkit-box-shadow: 1px 0px 2px 0px rgba(50, 50, 50, 0.2);
  -moz-box-shadow: 1px 0px 2px 0px rgba(50, 50, 50, 0.2);
  box-shadow: 1px 0px 2px 0px rgba(50, 50, 50, 0.2);
}

.dialog-title {
  font-size: 26px !important;
  font-weight: 300;
  margin: 0px 20px;
  margin-top: 20px;
}
.dialog-title-small {
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.dialog-text {
  font-size: 14px !important;
  color: #323232 !important;
}
.dialog-text ul {
  margin-left: 0px;
  padding-left: 24px;
}
.dialog-text ul li {
  margin-bottom: 5px;
}

.full-height {
  height: calc(100vh - 65px);
}
/* .copy-icon {
  font-size: 18px;
  color: gray;
  margin-left: 20px;
  margin-right: 20px;
} */
.copy-icon:hover {
  color: #595c62;
}
.link-textfield {
  width: 100px;
}
.MuiFilledInput-inputMarginDense {
  padding-top: 6px !important;
}
.copy-icon {
  color: #595c62;
  margin-bottom: 6px;
  margin-left: 6px;
  cursor: pointer;
}
.copy-icon:hover {
  color: gray;
}

.type-tile {
  color: white !important;
  text-align: center;
  border-radius: 10px;
  margin-right: 10px;
  width: 90px;
}
.type-tile .MuiChip-label {
  color: white;
  width: auto;
}
.type-tile.MuiChip-root {
  min-width: 70px;
  font-size: 12px;
}
.type-tile.audio {
  background-color: #51a871;
}
.type-tile.selection {
  background-color: #d6c847;
}
.type-tile.text {
  background-color: #578fc0;
}
.type-tile.screen {
  background-color: #c07ec2;
}
.type-tile.video {
  background-color: #a85151;
}
.type-tile.display {
  background-color: gray;
}
.selection_and {
  width: 130px;
  float: left;
  padding-right: 10px;
}

.sentiment.neutral {
  background-color: #b3b3b3;
}
.sentiment.positive {
  background-color: #22ce81;
}
.sentiment.negative {
  background-color: #ee9077;
}
.sentiment.mixed {
  background-color: #f7ee71;
}

.edit-icon {
  color: #595c62;
}
.edit-icon:hover {
  color: gray;
}

.graph-questions-container {
  min-height: 154px;
  text-align: center;
  padding: 10px;
}

.graph-title {
  font-size: 16px;
  padding-bottom: 5px;
  text-align: center;
}

.graph-questions-title {
  font-size: 16px;
  padding-bottom: 5px;
}

.MuiCheckbox-root {
  color: #595c62 !important ;
}

.MuiChip-root.tier-container {
  font-size: 13px !important;
  border-radius: 15px;
  text-align: center;
  color: white;
  margin-right: 10px;
  margin-left: 15px;
  cursor: pointer;
  padding: 0px 10px;
}

.navbar-nav > li > .dropdown-menu {
  border-radius: 5px !important;
}

.question-type-tile {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 20px;
}

.options-text {
  font-size: 14px;
  margin-bottom: 0px;
  min-width: 100px;
}

.survey-info-titles {
  font-size: 22px;
  margin-top: 10px;
}
.question-title-container.welcome {
  justify-content: center;
}

.MuiMenuItem-root {
  color: #595c62 !important;
}
.fine-print {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  color: #595c62;
}
.shown-to-user {
  color: lightgray;
}
.question-text {
  font-size: 20px;
}

.question-tool-tip {
  color: #595c62;
  font-size: 12px;
  cursor: pointer;
}
.question-tool-tip-container {
  margin-top: 10px;
}
.question-tool-tip:hover {
  color: lightgray;
}

.flex-image {
  width: 100%;
  height: auto;
  border: solid 1px #595c62;
  border-radius: 5px;
}

.survey-menu-icon {
  font-size: 14px;
  color: #595c62;
}
.survey-menu-container {
  margin: 15px;
  cursor: pointer;
}
.survey-menu-container:hover {
  opacity: 0.5;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.question-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.selection-options {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  float: right;
  margin-right: -4px !important;
}
.selection-options.outer {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
}
.selection-options-updown {
  display: flex;
  flex-direction: column;
}
.selection-option-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.comprehend-lang-not-supported-alert {
  margin-top: -5px;
  margin-bottom: 10px;
}
.pii-lang-not-supported-alert {
  margin-top: 10px;
  margin-bottom: 5px;
}
.transcription-options.outer {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-start;
  margin-top: 10px;
}
.transcription-options {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: -4px !important;
}
.transcription-options-indented {
  margin-left: 10px !important;
}
.prop-checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: -4px !important;
}

.timer-selector {
  margin-left: 10px !important;
  width: 80px;
  margin: 0px !important;
}
.checkbox-with-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.option-flexgrow {
  flex-grow: 1;
  margin-left: 0px;
  margin-right: 10px;
}
.option-fixedwidth {
  max-width: 150px;
}
.option-fullwidth {
  width: 100%;
}
.option-flexgrow.double {
  flex-grow: 3;
  margin-left: 10px;
  margin-right: 10px;
}
.selection-like-text {
  margin-top: 5px;
}

.survey-info-tip {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 12px;
}

.survey-info-alert {
  margin-top: 10px;
  margin-bottom: 10px;
}
.logic-alert {
  margin: 5px;
  width: 100%;
}

.random-checkbox-container {
  display: flex;
  margin-bottom: 9px;
}

.button-grayed.MuiButton-contained {
  opacity: 0.5 !important;
  background-color: gray !important;
}
.button-careful.MuiButton-contained {
  background-color: red !important;
}

.main-survey-tile {
  width: 200px;
  background-color: white;
  margin: 15px !important;
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 250px;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #4c4c4c;
}
.main-survey-tile:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
}
.main-survey-tile h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main-survey-tile p {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.template-survey-tile {
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #4c4c4c;
  transition: 0s !important;
  min-width: 130px;
}
.template-survey-tile:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
}

.top-hamburger {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 55px;
}
.top-hamburger-bookmark {
  float: left;
  margin-top: 10px;
  margin-right: 5px;
}
.main-survey-new {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #22ce81;
  background: linear-gradient(0deg, #1da166, #22ce81);
  color: white;
  border: none;
}

.template-survey-new {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #22ce81;
  background: linear-gradient(0deg, #1da166 0%, #22ce81 100%);
  color: white;
  margin: 0 !important;
  height: 140px;
  border: none;
}
.survey-state-tile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-date {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 12px !important;
}
.top-status {
  position: absolute;
  left: 0px;
  top: 15px;
  font-size: 12px;
  background: #55ac75;
  line-height: 20px;
  height: 20px;
  width: 90px;
  color: white;
  text-align: center;
  opacity: 0.75;
}
.top-status.pending {
  background: gray;
}
.top-status-background {
  position: absolute;
  left: 0px;
  top: 13px;
  background: #55ac75;
  height: 24px;
  width: 90px;
  opacity: 0.5;
}
.top-status-background.pending {
  background: gray;
}

.usage.MuiLinearProgress-root {
  height: 6px;
}
.usage.main-survey-tile p {
  font-size: 12px;
  margin-bottom: 3px;
}
.usage.main-survey-tile {
  justify-content: flex-start;
}

.usage.MuiButton-contained {
  background-color: white !important;
  border: solid 1px gray;
}
.usage .MuiButton-label {
  color: gray !important;
}

.usage .MuiLinearProgress-barColorPrimary {
  background-color: gray;
}
.usage .over .MuiLinearProgress-barColorPrimary {
  background-color: rgb(192, 92, 92);
}
.usage .MuiLinearProgress-colorPrimary {
  background-color: lightgray;
}
p.over {
  color: rgb(192, 92, 92);
}

.loader-container {
  margin-left: auto;
  margin-right: auto;
}

.signup.MuiCircularProgress-colorPrimary {
  color: white !important;
}

.email-verification .MuiInputBase-adornedEnd {
  background: white;
}
.email-verification .MuiCircularProgress-colorPrimary {
  color: gray !important;
}

.prompt-container {
  background: #fafafa;
  height: 100vh;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2d8677;
}
.prompt-container-indash {
  min-height: calc(100vh - 55px) !important;
  padding: 0px 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2d8677;
}

.create-survey-tabs {
  border-bottom: solid 1px lightgray;
  margin-bottom: 20px;
}
.create-survey-tabs.nomargin {
  border-bottom: solid 1px lightgray;
  margin-bottom: 0px;
}

.create-survey-buttons-container {
  width: calc(100% - 85px);
  position: fixed;
  bottom: 0px;
  left: 0;
  padding: 15px 15px;
  margin-left: 85px;
  background: white;
  border-top: solid 1px lightgray;
  z-index: 1000;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.display-none {
  display: none;
}
.themes-container {
  margin-bottom: 20px;
  padding: 20px;
  flex-grow: 1;
}
.image-preview {
  width: 150px;
  height: 150px;
  border: solid 1px lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: left;
  position: relative;
}

.image-preview-x {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
}
.image-preview-x:hover {
  opacity: 0.5;
}

.image-upload-container {
  width: 500px;
}

.custom-image-preview {
  max-height: 100%;
  max-width: 100%;
}
.preview-welcome-image {
  max-width: 90px;
  max-height: 90px;
  align-self: center;
  margin-bottom: 10px;
}

.image-upload-header {
  font-size: 12px;
}
.MuiButton-contained.gray-button {
  background-color: gray !important;
  opacity: 0.5;
}
.MuiButton-contained.secondary-button-color {
  background-color: #1d1c29 !important;
}
.MuiButton-outlined.outlined-button-color > .MuiButton-label {
  color: rgb(82, 82, 82) !important;
}
.MuiButton-contained.bottom-button {
  border-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.MuiIconButton-root.Mui-disabled {
  opacity: 0.5;
}

.media-selection .MuiSelect-selectMenu {
  display: flex;
  align-items: center;
}
.question-arrows {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.question-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.question-arrow {
  cursor: pointer;
}
.question-arrow:hover {
  opacity: 0.5;
}
.draft-table-create-survey {
  border: solid 1px lightgray;
  border-radius: 4px;
  max-height: 300px;
  min-width: 550px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

@media (max-width: 992px) {
  .create-survey-buttons-container {
    width: 100%;
    margin-left: 0px;
  }
}

h2.search-result {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: left;
  align-self: flex-start;
}
p.search-result {
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
  align-self: flex-start;
  color: gray;
}
div.search-result {
  border-bottom: 1px solid lightgray;
  height: 85px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  padding-left: 15px;
  width: 600px;
}
div.search-result.final {
  border-bottom: none;
}
div.search-result:hover {
  cursor: pointer;
  background-color: #fafafa;
}
.search-result .MuiChip-sizeSmall {
  margin-right: 5px;
  background: gray;
  color: white;
}
.search-result .completed.MuiChip-sizeSmall {
  background-color: rgba(46, 165, 145, 1);
  color: white;
}
.search-result .pending.MuiChip-sizeSmall {
  background-color: lightslategray;
  color: white;
}
.MuiChip-icon.search {
  color: white;
}

.icon-container {
  display: flex;
  align-items: center;
}
.icon-container .secondary.MuiSvgIcon-root {
  fill: #1d1c29;
}

.collapsible {
  overflow-y: hidden;
  transition: max-height 0.4s linear;
}

.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 16px !important;
}
.MuiAccordion-root.border-bottom {
  border-bottom: 1px solid lightgray;
}
.MuiAccordion-root:before {
  display: none;
}

.type-selection-logos {
  width: 40px;
  text-align: center;
}
.MuiSelect-select.Mui-disabled {
  background: #fafafa !important;
}

.header-flex {
  display: flex;
  align-items: center;
}
.header-state-container {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.standard-margin-top {
  margin-top: 10px;
}
.full-width {
  width: 100%;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-container.flex-column {
  flex-direction: column;
}
.flex-container.flex-start {
  align-items: flex-start;
}
.flex-container.flex-end {
  align-items: flex-end;
}
.flex-container.justify-left {
  justify-content: left;
}
.flex-container.justify-right {
  justify-content: right;
}
.flex-container.space10 {
  margin: 10px;
}
.flex-container.wrap,
.flex-container.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow-basis-1 {
  flex-basis: 1;
  flex-grow: 1;
}
.text-center {
  text-align: center;
}
.center-with-margins {
  margin-left: auto;
  margin-right: auto;
}

.instructions .MuiInputBase-inputMultiline {
  min-height: 75px;
  max-height: 1000px !important;
}
.MuiOutlinedInput-input {
  background-color: white !important;
}

.dummy-accordion-note {
  font-size: 16px;
  margin: 5px 0;
}

#wrap {
  width: 100%;
  height: 350px;
  padding: 0;
  overflow: hidden;
}

#frame {
  min-width: 600px;
  width: 200%;
  height: 1200px;
  border: 0;

  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.welcome-chips {
  margin: 0px 10px 20px 10px;
}

.surveylink .MuiOutlinedInput-input {
  cursor: pointer !important;
}
.surveylink .MuiOutlinedInput-root {
  background: white;
}

.options-popover {
  width: 200px;
}
.survey-option {
  border-top: solid 1px gray;
  font-size: 14px;
  padding: 5px;
}
.survey-option.gray {
  background: lightgray;
}

.chip-table-container {
  display: flex;
  justify-content: center;
}

.emoji-filter-container {
  height: 25px;
  width: 50%;
}
.emoji-filter {
  float: right;
  height: 25px;
  text-align: right;
}
.emoji-filter-element:hover {
  opacity: 0.5;
  cursor: pointer;
}

.main-panel.readonly {
  width: 100%;
}

.sharing-table {
  border: solid 1px lightgray;
  border-radius: 4px;
  min-width: 450px;
  margin-bottom: 20px;
}
.sharing-title {
  font-size: 16px;
}
.sharing-popup-container {
  width: 600px;
  margin-bottom: 20px;
}

.textfield-button-row {
  display: flex;
  flex-direction: row;
}

.rounded-icon-container {
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.rounded-icon-container:hover {
  opacity: 0.5;
  background: lightgray;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.circle-loader {
  margin-left: 15px;
  width: 20px;
  height: 20px;
}

.transcription-text {
  font-size: 14px;
  display: inline-block;
}
.transcription-text.large {
  font-size: 16px;
}

.transcription-text.highlighted {
  background-color: #28bf8f7a;
  border-radius: 2px;
  color: black;
  cursor: pointer;
}

.transcription-text.inherit {
  font-size: inherit;
}

.transcription-container {
  display: flex;
  flex-wrap: wrap;
}
.neutral-alert {
  max-width: 100%;
  border-radius: 5px;
  background: rgba(203, 203, 210, 0.15);
  padding: 12px;
  border: 1px lightgray solid;
}

.question-tabs {
  border-bottom: solid 1px lightgray;
  background: white;
}

.privacy-chip:hover {
  opacity: 0.5;
  cursor: pointer;
}

.option-skip-logic-container {
  margin-bottom: 30px;
}

.question-top-right-text {
  margin-right: 20px;
  font-weight: bold;
}

.sticky-header-table thead th {
  position: sticky;
  top: 0px;
  background: white;
}
.sticky-header-table thead tr {
  border-bottom: solid 1px black !important;
}

.flex-in-center-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.flex-in-center-fullscreen-90 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.absolute-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.record-button {
  background: #dc493a !important;
  color: white !important;
}
.record-button:hover {
  background: #ec493a !important;
}
.record-time-text {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 4px;
  width: 60px;
  background: rgba(255, 255, 255, 0.4);
  color: black;
  border-radius: 5px;
  border: 2px solid rgba(200, 200, 200, 0.6);
}

.dialog-fixed-width-md {
  width: 700px;
}

.sentiment-slider {
  flex: 1;
}
.sentiment-slider-box {
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid gray;
  padding: 5px 10px;
  min-width: 100px;
}
.sentiment-slider-box.Positive {
  background: #edffe2;
}
.sentiment-slider-box.Negative {
  background: #fff0f0;
}
.sentiment-slider-box.Mixed {
  background: #fffde3;
}
.sentiment-slider-box.Neutral {
  background: #fafafa;
}

.circle-container {
  position: absolute;
  height: 35px;
  width: 35px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
  margin-top: -15px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.6;
}
.circle-container:hover {
  opacity: 1;
}

.link-style {
  color: #28bf8f;
  cursor: pointer;
}
.link-style:hover {
  text-decoration: underline;
}

p.small {
  font-size: 14px;
}
p.gray {
  color: gray;
}

.MuiDrawer-root {
  height: 100px;
  background: white;
}

.action-buttons {
  height: 70%;
}

.med-scroller {
  border: 1px solid lightgray;
  border-radius: 5px;
  max-height: 500px;
  overflow-y: scroll;
  margin: 10px 0px;
}

#powered-by {
  width: 170px;
  height: 35px;
  background: gray;
  color: white;
  border-radius: 10px;
  font-size: 12px;
}
#powered-by img {
  margin-left: 5px;
  height: 30px;
}

.fixed-bottom {
  position: fixed;
  bottom: 10px;
}

.ranking-result {
  word-wrap: break-word;
  hyphens: auto;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 5px 10px;
  margin: 5px;
  max-width: 180px;
}

.session-device-chip-container {
  margin: 0 12px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
}

.device-type-chip {
  height: 20px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  border: 1px rgb(223, 223, 223) solid;
  border-radius: 5px;
  margin-right: 8px;
}

.device-type-chip-left {
  height: 100%;
  background-color: rgb(235, 235, 235);
  border-radius: 4px 0 0 4px;
  float: left;
  padding: 0 8px;
}

.device-type-chip-right {
  height: 100%;
  background-color: rgb(211, 234, 229);
  border-radius: 0 4px 4px 0;
  float: right;
  font-weight: bold;
  padding: 0 8px;
}

.split-chip-margin > * {
  margin-top: 2px;
  margin-bottom: 2px;
}
.split-chip-margin > :first-child {
  margin-top: 8px;
}
.split-chip-margin > :last-child {
  margin-bottom: 0px;
}

/* File Browser Styles */
.file-browser-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 1px #bfcccf solid;
  padding-top: 15px;
}
.file-browser {
  z-index: 1;
  overflow: hidden auto;
  margin-right: 0px;
  margin-bottom: 0px;
}
.add-new-file-browser {
  height: 30px;
  padding: 20px;
  width: 100%;
  float: bottom;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #818c8e;
  white-space: nowrap;
  min-width: 0px;
  overflow: visible;
  text-overflow: ellipsis;
  border-top: 1px #bfcccf solid;
}
.add-new-file-browser .file-button-wrapper {
  border: 1px #ccd6d9 solid;
  margin-right: 12px;
}

.files-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  font-size: 14px;
  padding: 0px 14px 0px 15px;
  width: 100%;

  flex: 1 1 auto;
  white-space: nowrap;
  min-width: 0px;
  overflow: visible;
  text-overflow: ellipsis;
}

.file-group-outer {
  display: flex;
  align-items: center;
  min-height: 27px;
  font-size: 14px;
  width: 100%;
}
.file-group-inner {
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 3px;
  color: rgba(55, 53, 47, 0.6);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
}
.file-button-wrapper:hover {
  background-color: rgba(214, 214, 214, 0.6);
}

.file-button-wrapper {
  min-width: 20px;
  border-radius: 3px;
  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: px;
}

.file-group-item-text {
  flex: 1 1 auto;
  white-space: nowrap;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.file-group-item {
  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
  width: 100%;
}
.file-group-item:hover {
  text-decoration: none !important;
  background-color: rgba(233, 233, 233, 0.6);
}
.file-group {
  display: block;
  color: inherit;
  text-decoration: none !important;
  width: 100%;
}

.collection-title-container {
  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 2px 4px;
  margin-left: -4px;
}
.collection-title {
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-size: 11.5px;
  line-height: 1;
  margin-bottom: 1px;
  color: rgba(55, 53, 47, 0.4);
  font-weight: 600;
  transition: color 100ms ease-out 0s;
}
.notranslate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.display-block {
  display: block;
}
.file-group-triangle {
  width: 0.6875em;
  height: 0.6875em;
  display: block;
  fill: rgba(55, 53, 47, 0.4);
  flex-shrink: 0;
  backface-visibility: hidden;
  transition: transform 200ms ease-out 0s;
  opacity: 1;
}

.file-button {
  width: 0.6875em;
  height: 0.6875em;
  flex-shrink: 0;
  backface-visibility: hidden;
  transition: transform 200ms ease-out 0s;
  opacity: 1;
}
.file-browser .file-button.plus {
  display: none;
  fill: none;
}
.file-browser:hover .file-button.plus {
  color: rgba(55, 53, 47, 0.4);
  display: block;
}
.file-browser:hover .files-header .file-button-wrapper {
  border: 1px #ccd6d9 solid;
}
.file-group-item .file-button {
  display: none;
  fill: none;
}
.file-group-item:hover .file-button {
  color: rgba(55, 53, 47, 0.4);
  display: block;
}

.file-demographics-edit-button {
  padding-top: 10px;
  display: none;
}

.file-demographics:hover .file-demographics-edit-button {
  display: inline-block;
}
.folder-tile {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px #f0f0f0 solid;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  position: relative;
  border-radius: 5px;
  color: #4c4c4c;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  border-top: 8px #22ce81 solid;
}
.folder-tile:hover {
  box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.4);
}
.folder-tile .title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 8px;
}
.folder-tile .date {
  color: #818c8e;
  font-size: 12px;
  text-transform: uppercase;
}
.folder-tile hr {
  width: 100%;
}
.folder-tile .stat {
  width: 100px;
  display: inline-block;
}
.folder-tile .num {
  font-size: 18px;
  color: #1d1c29;
}
.folder-tile .unit {
  font-size: 12px;
  color: #818c8e;
}
.file-viewer-count {
  margin-left: 12px;
  color: #818c8e;
  font-weight: 400;
}
.hover-button {
  color: #1d1c29;
}
.hover-button:hover {
  opacity: 0.5;
  cursor: pointer;
}
.hover-button.disabled {
  color: gray;
  opacity: 0.2;
}

.tutorial-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.no-items-container {
  width: 100%;
  padding-top: 100px;
  color: gray;
  text-align: center;
}
