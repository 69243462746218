@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

.my-2 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mr-1 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.hidden {
  display: none;
}

.font-bold {
  font-weight: 700;
}
