$phonic-green: #62cb88;
$phonic-green-bright: #81d59f;
$phonic-green-dark: #448e5f;
$phonic-blue: #1d1c29;
$subtitle-gray: #757575;
$off-black: #1d1c29;
$fullscreen-subtract-header: calc(100vh - 55px);
$fullscreen-subtract-header-div2: calc((100vh - 55px) / 2);
$standard-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
$dark-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
$left-results-pane-width-l: 350px;
$left-results-pane-width-m: 250px;
$left-results-pane-width-s: 100px;
